<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }">
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="belong_outlets_city_adcodes" label="城市市场">
              <a-select style="width: 100%" placeholder="请选择城市"
                        v-model="searchForm.belong_outlets_city_adcodes">
                <a-select-option v-if="!isCityManage" key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in cityList"
                                 :key="index"
                                 :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="outlets_id"
                               label="管理网点">
              <a-select show-search allowClear
                        placeholder="请输入负责网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="handlerSuggestOutlets"
                        v-model="searchForm.outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.showName}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="状态"
                               prop="status">
              <a-select v-model="searchForm.status">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="-1">禁用</a-select-option>
                <a-select-option value="1">正常</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="name"
                               label="员工账号">
              <a-input allowClear
                       v-model="searchForm.searchName"
                       placeholder="请输入 姓名 / 账号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="staff_role_id"
                               label="角色">
              <a-select placeholder="选择角色权限" style="width: 100%"
                        v-model="searchForm.staff_role_id"
                        show-search
                        allowClear
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSuggestRoles">
                <a-select-option v-for="item in suggestRoleList"
                                 :key="item.id"
                                 :value="item.id.toString()">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <div class="flex justify-end" style="position: relative;top: 4px;">
              <a-button @click="handlerSearch"
                        type="primary"
                        html-type="submit">搜索</a-button>
              <a-button style="margin-left: 10px"
                        @click="resetForm">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData"
                :total="searchForm.total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">员工账号</h4>
          <a-button class="ml-3"
                    type="primary"
                    @click="handlerEdit('add')"
                    html-type="submit">新增</a-button>
        </div>
      </template>

      <template #status="{text}">
        <a-tag :color="text===1? 'green' : 'red' " class="m-0">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #is_shop_client="{text}">
        <template>{{text == 2 ? "是" : "" }}</template>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="pl-0 pr-0 ml-2 mr-2"
                  @click="handlerEdit('edit',record)">编辑</a-button>
        <a-button type="link" class="pl-0 pr-0 ml-2 mr-2"
                  @click="handlerUpdateStatus(record)">{{record.status === 1 ? '停用' : '启用'}}</a-button>
      </template>
    </base-table>

    <regional-add-modal v-if="isEidtShow"
               :show.sync="isEidtShow"
               :id="activeId"
               :type="type"
               :cityAdcode="searchForm.belong_outlets_city_adcodes"/>
  </div>
</template>

<script>
import {
  getList, updateStatus
} from "@/api/system/staff.js"
import { getOutletsList } from '@/api/customer'
import {
  getList as getRoleList
} from "@/api/system/role.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'


import regionalAddModal from './components/regional-add-modal.vue'

export default {
  components: { regionalAddModal },
  data () {
    return {
      cityList: [],
      outletsList: [],
      searchForm: {
        name: "",
        phone: "",
        outlets_id: undefined,
        financial_account_id: "",
        outlets_city_adcode: undefined,
        belong_outlets_city_adcodes: undefined,
        staff_role_id: undefined,
        status: '1',
        total: 0,
        page: 1,
        page_count: 20,
      },
      columns: [
        {
          title: "账号ID",
          dataIndex: "id",
          align: "center",
          width: 70,
        },
        {
          title: "姓名",
          dataIndex: "name",
          align: "center",
          // width: 90,
        },
        {
          title: "登录手机号",
          dataIndex: "phone",
          align: "center",
          width: 120,
        },
        {
          title: "负责的网点",
          dataIndex: "outlets_name",
          align: "center",
        },
        // {
        //   title: "管理的财务账户",
        //   dataIndex: "financial_accounts",
        //   align: "center",
        // },
        // {
        //   title: "管理的城市",
        //   dataIndex: "outlets_citys",
        //   align: "center",
        // },
        {
          title: "角色",
          dataIndex: "staff_role_names",
          align: "center",
        },
        {
          title: "门店端使用",
          dataIndex: "is_shop_client",
          align: "center",
          slots: { customRender: "is_shop_client" },
          width: 110,
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
          width: "70px",
          slots: { customRender: 'status' }
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: "140px",
        },
      ],

      type: "add",
      activeId: '',
      tableData: [],
      roleList: [],
      suggestRoleList: [],
      isEidtShow: false,

      isCityManage: isCityManage(),

      timerOutlets: null,
    }
  },
  async mounted () {
    this.initRoleList()
    this.cityList = await getMarketCityList()

    this.initData()
  },
  methods: {
    async initData () {
      if(this.isCityManage){
        if(!this.searchForm.belong_outlets_city_adcodes){
          this.searchForm.belong_outlets_city_adcodes = this.cityList[0].adcode
        }
      }

      const { data, code } = await getList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.searchForm.total = data.total_count
      }
    },

    async initRoleList () {
      const { code, data } = await getRoleList()
      if(code == 0){
        this.roleList = data.list.filter(el=>el.type==2 || el.type==3 || el.type == 4)
      }
      this.suggestRoleList = this.roleList || []
    },

    handleSuggestRoles(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestRoleList = this.roleList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestRoleList = this.roleList
      }
    },
    
    async handlerSuggestOutlets (keyword) {
      if(this.timerOutlets){
        clearTimeout(this.timerOutlets)
        this.timerOutlets = null
      }
      this.timerOutlets = setTimeout(()=>{
        this.getSuggestOutlets(keyword)
      }, 100)
    },
    async getSuggestOutlets(keyword){
      if (keyword) {
        const params = {
          ignore_close: 2,
          outlets_city_adcode: this.searchForm.belong_outlets_city_adcodes,
          outlets_name: keyword,
          count: 10,
        }
        const { data, code } = await getOutletsList(params)
        if (code === 0) {
          this.outletsList = data.list.map(el=>{
            let status = el.outlets_status == 2 ? "【未发布】" : (el.outlets_status == 3 ? "【营业中】" : "")
            el.showName = status + el.outlets_name
            return el
          })
        }
      }else{
        this.outletsList = []
      }
    },

    handlerSearch(){
      if(/^1\d{10}$/.test(this.searchForm.searchName||"")){
        this.searchForm.name = ""
        this.searchForm.phone = this.searchForm.searchName
      }else{
        this.searchForm.name = this.searchForm.searchName
        this.searchForm.phone = ""
      }
      this.searchForm.page = 1
      this.initData()
    },

    handlerEdit (type, row) {
      if(!this.searchForm.belong_outlets_city_adcodes){
        this.$message.info("请先选择城市")
        return
      }
      this.type = type
      if(type == "add"){
        this.activeId = ''
        this.isEidtShow = true
      }
      if(type == "edit"){
        this.activeId = row.id
        this.isEidtShow = true
      }
    },

    handlerUpdateStatus (row) {
      const _this = this
       this.$confirm({
        title: "温馨提示",
        content: `是否确认${row.status === 1 ? '停用': '恢复'}该账号？`,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updateStatus({ id: row.id, status: row.status === 1? -1: 1 }).then(
            (res) => {
              if (res.code === 0) {
                _this.$message.success("操作成功！")
                _this.initData()
              }
            }
          )
        },
      })
    },

    resetForm () {
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
</style>