<template>
  <div>
    <!-- 城市：新增/编辑 账号 -->
    <a-modal :visible="show"
             width="600px"
             :title="type=='add'?'新增': '编辑'"
             @ok="handlerSave"
             @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }"
                    :model="formData">

        <a-form-model-item prop="belong_outlets_city_adcode" label="账号归属">
          <a-select style="width: 100%" placeholder="请选择城市"
                    disabled
                    v-model="formData.belong_outlets_city_adcode">
            <a-select-option v-for="(item, index) in cityList"
                             :key="index"
                             :value="item.adcode.toString()">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="name"
                           :rules="requiredRule"
                           label="员工姓名">
          <a-input allowClear
                   v-model="formData.name"
                   placeholder="请输入员工姓名"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="phone"
                           :rules="requiredRule"
                           label="登录手机号">
          <a-input style="width:100%"
                   allowClear
                   v-model="formData.phone"
                   placeholder="请输入手机号"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="password"
                           label="密码">
          <a-input style="width:100%"
                   allowClear
                   type="new-password"
                   v-model="formData.password"
                   placeholder="请输入密码"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="staff_role_ids"
                           :rules="requiredRule"
                           label="账号角色">
          <a-select placeholder="请选择角色权限" style="width: 100%"
                    v-model="formData.staff_role_ids"
                    mode="multiple"
                    show-search
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    @search="handleSuggestRoles">
            <a-select-option v-for="item in suggestRoleList"
                             :key="item.id"
                             :value="item.id.toString()">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item v-if="myRoleTypeList.indexOf(4)>-1" 
                           prop="outlets_city_adcodes"
                           label="管理的城市">
          <a-select style="width: 100%" placeholder="请选择城市"
                    v-model="formData.outlets_city_adcodes">
            <a-select-option v-for="(item, index) in cityList"
                             :key="index"
                             :value="Number(item.adcode)">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item v-if="myRoleTypeList.indexOf(3)>-1" 
                           prop="financial_account_ids"
                           label="管理的财务">
          <a-select style="width: 100%" placeholder="管理的财务"
                    mode="multiple"
                    v-model="formData.financial_account_ids">
            <a-select-option v-for="item in accountList"
                             :key="item.id"
                             :value="item.id.toString()">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item v-if="myRoleTypeList.indexOf(2)>-1"
                      prop="outlets_id"
                      label="负责的网点">
          <div class="flex justify-between items-center">
            <a-select show-search style="flex:1;"
                      placeholder="请输入负责网点"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handlerSuggestOutlets"
                      v-model="formData.outlets_id">
              <a-select-option v-for="(item, index) in suggestOutletsList"
                               :key="index"
                               :value="item.outlets_id">{{item.showName}}</a-select-option>
            </a-select>
            <a-checkbox class="ml-4" v-model="isShopClient">门店端软件使用</a-checkbox>
          </div>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getDetail,
  add,
  update
} from "@/api/system/staff.js"
import {
  getList
} from "@/api/system/account.js"
import {
  getList as getRoleList
} from "@/api/system/role.js"
import { requiredRule } from '@/hooks/use-form-rules'
import { getOutletsList } from '@/api/customer'
import { 
  getMarketCityList,
} from '@/utils/authData.js'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: [String, Number],
      default: ''
    },
    cityAdcode: {
      type: [String, Number],
      default: ''
    },
  },
  data () {
    return {
      requiredRule,

      isShopClient: false,

      formData: {
        name: "",
        phone: "",
        password: "",
        outlets_id: undefined,
        is_shop_client: 1,
        outlets_city_adcodes: undefined,
        financial_account_ids: [],
        staff_role_ids: [],
        belong_outlets_city_adcode: undefined,
      },

      suggestOutletsList: [],
      cityList: [],
      accountList: [],
      roleList: [],
      suggestRoleList: [],
      myRoleTypeList: [],

      timerOutlets: null,
    }
  },
  async mounted () {
    if(this.type == 'add'){
      this.formData.belong_outlets_city_adcode = String(this.cityAdcode)
    }
    if (this.type == 'edit'){
      await this.initData()
    }
    
    const cityList = await getMarketCityList(true)
    this.cityList = cityList.filter((el)=>{
      return el.adcode == this.formData.belong_outlets_city_adcode
    })

    this.initRoleList()
    this.accountList = await this.getFinanceList()
  },

  methods: {

    async initData () {
      const { data } = await getDetail({ id: this.id })
      if (data.belong_outlets_city_adcode == '0') {
        data.belong_outlets_city_adcode = ""
      }
      if (data.outlets_city_adcodes == '0') {
        data.outlets_city_adcodes = ""
      }
      if(data.financial_account_ids == "0"){
        data.financial_account_ids = ""
      }
      if (data.outlets_id == '0') {
        data.outlets_id = ""
      }
      if(data.belong_outlets_city_adcode){
        data.belong_outlets_city_adcode = String(data.belong_outlets_city_adcode)
      }

      this.formData = data
      this.formData.id = this.id
      
      // this.formData.outlets_city_adcodes  = data.outlets_city_adcodes ? data.outlets_city_adcodes.split(',') : []
      this.formData.financial_account_ids = data.financial_account_ids ? data.financial_account_ids.split(',') : []
      this.formData.staff_role_ids        = data.staff_role_ids ? data.staff_role_ids.split(',') : []

      if(data.outlets_name){
        this.suggestOutletsList = [{
          outlets_id: data.outlets_id,
          // outlets_name: data.outlets_name,
          showName: data.outlets_name,
        }]
      }
      this.isShopClient = data.is_shop_client == 2
    },

    async initRoleList () {
      const { code, data } = await getRoleList()
      if(code == 0){
        this.roleList = data.list.filter(el=>el.type==2 || el.type==3 || el.type == 4)
      }
      this.suggestRoleList = this.roleList || []
      this.updateMyRoles(this.formData.staff_role_ids)
    },

    async handlerSuggestOutlets (keyword) {
      if(this.timerOutlets){
        clearTimeout(this.timerOutlets)
        this.timerOutlets = null
      }
      this.timerOutlets = setTimeout(()=>{
        this.getSuggestOutlets(keyword)
      }, 100)
    },
    async getSuggestOutlets(keyword){
      if (keyword) {
        const params = {
          ignore_close: 2,
          outlets_name: keyword,
          outlets_city_adcode: this.formData.belong_outlets_city_adcode,
          count: 10,
        }
        const { data, code } = await getOutletsList(params)
        if (code === 0) {
          this.suggestOutletsList = data.list.map(el=>{
            let status = el.outlets_status == 2 ? "【未发布】" : (el.outlets_status == 3 ? "【营业中】" : "")
            el.showName = status + el.outlets_name
            return el
          })
        }
      }else{
        this.suggestOutletsList = []
      }
    },

    async getFinanceList(){
      const { code, data } = await getList({
        belong_outlets_city_adcode: this.formData.belong_outlets_city_adcode || "",
        is_city: 2
      })
      if (code == 0) {
        return data.list
      }
      return []
    },

    handlerSave () {
      // 不是市场、城市置空
      if(this.myRoleTypeList.indexOf(4) == -1){
        this.formData.outlets_city_adcodes = ""
      }
      // 不是财务、财务账户置空
      if(this.myRoleTypeList.indexOf(3) == -1){
        this.formData.financial_account_ids = []
      }
      // 不是门店、网点置空
      if(this.myRoleTypeList.indexOf(2) == -1){
        this.formData.outlets_id = ""
      }
      // 是否门店端使用
      if(!this.formData.outlets_id){
        this.isShopClient = false
      }
      this.formData.is_shop_client = this.isShopClient?2:1

      const params = JSON.parse(JSON.stringify(this.formData))
            // params.outlets_city_adcodes   = params.outlets_city_adcodes.join(',')
            params.financial_account_ids  = params.financial_account_ids.join(',')
            params.staff_role_ids         = params.staff_role_ids.join(',')

      if (this.type === "add") {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        update(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },
    updateMyRoles(my_role_ids){
      let mytypes = [];
      my_role_ids.map(roleid =>{
        let role = this.roleList.find(r => {
          return r.id == roleid
        })
        if(role){
          mytypes.push(role.type)
        }
      })
      this.myRoleTypeList = mytypes
    },

    handleSuggestRoles(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestRoleList = this.roleList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestRoleList = this.roleList
      }
    },
    
  },
  watch: {
    'formData.staff_role_ids' (vals) {
      this.updateMyRoles(vals)
    },
  }
}
</script>

<style>
</style>